
import { computed, defineComponent, onMounted, ref } from "vue";
import CardTitleJaya from "@/components/global/CardTitleJaya.vue";
import { useStore } from "vuex";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { Actions } from "@/store/enums/StoreEnums";
import {
  Diagnostic,
  EcosystemicServicesCategory,
  Factor,
} from "@/store/modules/DiagnosticsModule";
import { useRouter } from "vue-router";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { Exploitation, OTEX, SIQO } from "@/store/modules/ExploitationsModule";
import { notify, parseDate } from "@/core/helpers/globalJaya";

export default defineComponent({
  name: "DiagnosticEcosystemicServices",
  props: {
    categoryId: String,
    diagnosticId: String,
  },
  components: {
    CardTitleJaya,
    Datatable,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    let chartType = ref<string>("radialBar");
    interface SeriesObject {
      name: string;
      data: number[];
    }
    let seriesMean = ref<any>();
    let seriesBar = ref<SeriesObject[]>([]);

    let diagnostic = ref<Diagnostic>({
      id: -1,
      date_evaluated: new Date().toISOString(),
      is_real: false,
      finished: false,
      exploitation: -1,
      exploitation_id: -1,
      date_created: new Date().toISOString(),
      results: [],
    });
    let factors = ref<Array<Factor>>([]);
    let exploitation = ref<Exploitation>({
      id: -1,
      date_created: new Date().toISOString(),
      exploitation_versions: [],
    });
    let editingOTEX = ref<OTEX>({ id: null });
    let editingSIQO = ref<SIQO>({ id: null });

    interface DepartmentObject {
      id: number;
      name?: string;
      insee_dep?: number;
    }
    let editingDepartment = ref<DepartmentObject>({
      id: -1,
    });
    let departments = ref<Array<DepartmentObject>>([]);

    let displayMeans = ref<boolean>(false);
    let displayTime = ref<boolean>(false);
    const getOTEX = computed(() => store.getters.getOTEX);
    const getSIQO = computed(() => store.getters.getSIQO);

    let ecosystemicServicesCategory = ref<EcosystemicServicesCategory>({
      id: -1,
      name: "",
      services: [],
    });

    const computeScore = (obj, diagnosticValue) => {
      const activeFactorLevels = obj.factor_levels.filter((e) => e.active);
      const max_score = activeFactorLevels.length;
      let score = 0;
      diagnosticValue.results.forEach((r) => {
        const level = r.level_details.level;
        const factorId = r.level_details.factor;
        const factorIdFromQuiz = factors.value.find((f) => f.id === factorId);
        const factorLevelIds = factorIdFromQuiz?.levels.map((l) => l.id);

        score += activeFactorLevels.filter((a) => {
          return (
            factorLevelIds?.includes(a.factor_level.id) &&
            a.factor_level.level <= level
          );
        }).length;
      });
      return score / max_score;
    };

    let series = computed(() => {
      if (ecosystemicServicesCategory.value) {
        return ecosystemicServicesCategory.value.services
          .map((e) =>
            Math.round(computeScore(e, diagnostic.value) * 100 + Number.EPSILON)
          )
          .sort((a, b) => (a < b ? -1 : 1));
      }
      return [];
    });

    const updateSeriesBar = () => {
      seriesBar.value = [];
      if (ecosystemicServicesCategory.value) {
        if (displayTime.value) {
          const diagnostics = store.getters.getDiagnostics
            .filter((e) => {
              return (
                e.exploitation_id == diagnostic.value.exploitation_id &&
                e.finished
              );
            })
            .sort((d1, d2) =>
              new Date(d1.date_evaluated).getTime() <
              new Date(d2.date_evaluated).getTime()
                ? -1
                : 1
            );
          for (const diag of diagnostics) {
            seriesBar.value.push({
              data: ecosystemicServicesCategory.value.services.map((e) =>
                Math.round(computeScore(e, diag) * 100 + Number.EPSILON)
              ),
              name: parseDate(diag.date_evaluated),
            });
          }
        } else {
          seriesBar.value.push({
            data: ecosystemicServicesCategory.value.services.map((e) =>
              Math.round(
                computeScore(e, diagnostic.value) * 100 + Number.EPSILON
              )
            ),
            name: "Vos données",
          });
          if (displayMeans.value) {
            let name = "Moyennes (";
            name +=
              editingOTEX.value.id === -1
                ? "Tous les OTEX"
                : "OTEX : " + getOTEXName();
            name +=
              editingSIQO.value.id === -1
                ? " et tous les SIQO)"
                : " et SIQO : " + getSIQOName() + ")";
            seriesBar.value.push({
              data: ecosystemicServicesCategory.value.services.map(
                (e) => seriesMean.value[e.short_name]
              ),
              name: name,
            });
          }
        }
      }
    };
    const getOTEXName = () => {
      const otex = getOTEX.value.find((e) => {
        return e.id === editingOTEX.value.id;
      });
      if (otex) {
        return otex.name;
      }
      return "";
    };

    const getSIQOName = () => {
      const siqo = getSIQO.value.find((e) => {
        return e.id === editingSIQO.value.id;
      });
      if (siqo) {
        return siqo.name;
      }
      return "";
    };
    const correctServiceColor = (result) => {
      if (result <= 33.3) {
        return getCSSVariableValue("--bs-danger");
      } else if (result <= 66.6) {
        return getCSSVariableValue("--bs-warning");
      } else if (result <= 100) {
        return getCSSVariableValue("--bs-primary");
      }
    };

    let chartOptions = computed(() => {
      return {
        chart: {
          type: "radialBar",
          height: 350,
          width: 350,
          zoom: {
            enabled: false,
          },
        },
        series: series.value,
        colors: [({ value }) => correctServiceColor(value)],
        labels: ecosystemicServicesCategory.value.services.map(
          (e) => e.short_name
        ), //FixMe: sort also legend
        plotOptions: {
          radialBar: {
            inverseOrder: false,
            startAngle: -179,
            endAngle: 130,
            hollow: {
              margin: 1,
              size: "50%",
              background: "transparent",
              position: "front",
            },
            track: {
              show: true,
              startAngle: undefined,
              endAngle: undefined,
              background: "#f2f2f2",
              strokeWidth: "97%",
              opacity: 1,
              margin: 5,
              dropShadow: {
                enabled: false,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.5,
              },
            },
            dataLabels: {
              show: true,
              name: {
                show: true,
                fontSize: "30px",
                fontFamily: undefined,
                fontWeight: 200,
                color: undefined,
                offsetY: -15,
              },
              value: {
                show: true,
                fontSize: "30px",
                fontFamily: undefined,
                fontWeight: 600,
                color: undefined,
                offsetY: 16,
                formatter: function (val) {
                  return val + "%";
                },
              },
              total: {
                show: false,
                label: ecosystemicServicesCategory.value.name,
                color: "#373d3f",
                fontSize: "16px",
                fontFamily: undefined,
                fontWeight: 600,
                formatter: function () {
                  return "";
                },
              },
            },
          },
        },
        legend: {
          show: true,
          floating: true,
          fontSize: "13px",
          fontWeight: 600,
          position: "left",
          horizontalAlign: "left",
          offsetX: 150,
          offsetY: setOffsetYLegend(),
          inverseOrder: true,
          labels: {
            useSeriesColors: true,
          },
          markers: {
            width: 0,
          },
          formatter: function (seriesName, opts) {
            return (
              ecosystemicServicesCategory.value.services[opts.seriesIndex]
                .short_name +
              " - " +
              opts.w.globals.series[opts.seriesIndex] +
              " %"
            );
          },
          itemMargin: {
            vertical: 0,
          },
          onItemClick: {
            toggleDataSeries: false,
          },
        },
      };
    });
    let chartOptionsBar = computed(() => {
      return {
        chart: {
          type: "bar",
          height: 350,
          width: 350,
          zoom: {
            enabled: false,
          },
        },
        series: seriesBar.value,
        colors:
          displayMeans.value || displayTime.value
            ? [
                getCSSVariableValue("--bs-primary"),
                getCSSVariableValue("--bs-secondary"),
                getCSSVariableValue("--bs-dark"),
                getCSSVariableValue("--bs-warning"),
                getCSSVariableValue("--bs-danger"),
              ]
            : [({ value }) => correctServiceColor(value)],
        labels: ecosystemicServicesCategory.value.services.map(
          (e) => e.short_name
        ),
        plotOptions: {
          bar: {
            vertical: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        legend: {
          position: "bottom",
          horizontalAlign: "left",
        },
      };
    });
    const setType = (type) => {
      chartType.value = type;
    };

    const setOffsetYLegend = () => {
      if (ecosystemicServicesCategory.value.services.length === 3) {
        return 222;
      }
      return 230;
    };

    let classRadial = computed(() => {
      let className = "";
      if (displayTime.value || displayMeans.value) {
        className = className + "disabled";
      }
      if (chartType.value === "radialBar") {
        className = className + " btn-light-primary";
      }
      return className;
    });

    onMounted(() => {
      store
        .dispatch(Actions.GET_LOCATIONS, { type: "departments", limit: 150 })
        .then((response) => {
          departments.value = response.results;
        });

      Promise.all([
        store.dispatch(Actions.FETCH_QUIZ),
        store.dispatch(Actions.FETCH_ECOSYSTEMIC_SERVICES_CATEGORIES),
        store.dispatch(Actions.FETCH_DIAGNOSTICS),
        store.dispatch(Actions.FETCH_EXPLOITATIONS),
      ]).then(() => {
        setCurrentPageTitle("Services Ecosystémiques");

        factors.value = store.getters.getFactors;

        ecosystemicServicesCategory.value =
          store.getters.getEcosystemicServicesCategories.find(
            (e) => e.id === Number(props.categoryId)
          );

        diagnostic.value = store.getters.getDiagnostics.find(
          (diagnostic) => diagnostic.id === Number(props.diagnosticId)
        );

        exploitation.value = store.getters.getExploitations.find(
          (e) => e.id === diagnostic.value.exploitation_id
        );
        updateSeriesBar();
        if (
          exploitation.value.exploitation_versions.some(
            (e) => e.id === diagnostic.value.exploitation
          )
        ) {
          const otex_id = exploitation.value.exploitation_versions.find(
            (e) => e.id === diagnostic.value.exploitation
          )?.otex?.id;
          editingOTEX.value.id = otex_id ? otex_id : -1;
        }
      });
    });

    const legendData = computed(() => {
      return store.getters.getEcosystemicServicesCategories.find(
        (e) => e.id === Number(props.categoryId)
      ).services;
    });

    const displayServiceDetail = (service) => {
      let content =
        service.description +
        "<br><br>" +
        "<strong>Représentativité : </strong>" +
        service.representativeness +
        "<br><br>" +
        "<strong>Intérêt :  </strong>" +
        service.interest +
        "<br><br>" +
        "<strong>Pratiques non estimées : </strong><br>";

      service.not_estimated_practices.forEach((nep) => {
        content +=
          "<div>" + nep[0] + " : <strong>" + nep[1] + "</strong></div>";
      });

      Swal.fire({
        title: service.name,
        grow: "column",
        width: "40em",
        html: content,
        buttonsStyling: false,
        confirmButtonText: "Détails du service",
        showCancelButton: true,
        cancelButtonText: "Retour",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
          cancelButton: "btn fw-bold btn-light-warning",
        },
        showCloseButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          router.push({
            name: "ecosystemic-services-list",
            params: {
              serviceId: Number(service.id),
            },
          });
        }
      });
    };

    const onDataPointSelection = (event, chartContext, config) => {
      const service =
        ecosystemicServicesCategory.value.services[config.dataPointIndex];

      displayServiceDetail(service);
    };

    const tableHeader = ref([
      {
        name: "",
        key: "service",
        sortable: false,
      },
      {
        name: "Légende des Services Ecosystémiques",
        key: "description",
        sortable: false,
      },
      {
        name: "",
        key: "info",
        sortable: false,
      },
    ]);

    const diagnosticName = computed(() => {
      if (diagnostic.value.id > 0) {
        const date_created = parseDate(diagnostic.value.date_created);
        if (
          store.getters.getExploitations.some(
            (exploitation) =>
              exploitation.exploitation_versions[0].id ===
              diagnostic.value.exploitation
          )
        ) {
          var linkedExploitationName = store.getters.getExploitations.find(
            (exploitation) =>
              exploitation.exploitation_versions[0].id ===
              diagnostic.value.exploitation
          ).name;
          return (
            "Diagnostic de '" +
            linkedExploitationName +
            ", créé le " +
            date_created +
            " (année culturale " +
            new Date(diagnostic.value.date_evaluated).getFullYear() +
            ")"
          );
        }
      }
      return "";
    });

    const fetchMeans = () => {
      let payload = ref<any>({});
      if (editingOTEX.value.id !== -1) {
        payload.value.otex = [editingOTEX.value.id];
      }
      if (editingSIQO.value.id !== -1) {
        payload.value.siqo = [editingSIQO.value.id];
      }
      payload.value.departments =
        editingDepartment.value.id !== -1 ? [editingDepartment.value.id] : [];
      return store
        .dispatch(Actions.FETCH_MEAN_ECOSYSTEMIC_SERVICES, payload.value)
        .then((response) => {
          if (response.data[0]["stats"]["avg"]["PABNA"] === 0) {
            notify({
              text: "Pas assez de données dans cette catégorie. Veuillez séléctionner des données différentes",
              color: "error",
              duration: 6000,
            });
          } else {
            chartType.value = "None";
            displayMeans.value = true;
            displayTime.value = false;
            seriesMean.value = response.data[0]["stats"]["avg"];

            updateSeriesBar();
            chartType.value = "bar";
          }
        });
    };

    const fetchTime = () => {
      if (!displayTime.value) {
        chartType.value = "None";
        displayTime.value = true;
        displayMeans.value = false;
        updateSeriesBar();
        chartType.value = "bar";
      }
    };
    const resetCharts = () => {
      chartType.value = "radialBar";
      displayMeans.value = false;
      displayTime.value = false;
      updateSeriesBar();
    };
    return {
      ecosystemicServicesCategory,
      diagnosticName,
      chartOptions,
      chartOptionsBar,
      series,
      seriesBar,
      tableHeader,
      legendData,
      chartType,
      editingOTEX,
      editingSIQO,
      editingDepartment,
      departments,
      getOTEX,
      getSIQO,
      getOTEXName,
      getSIQOName,
      setType,
      fetchMeans,
      fetchTime,
      onDataPointSelection,
      displayServiceDetail,
      resetCharts,
      displayMeans,
      displayTime,
      classRadial,
    };
  },
});
